.add-payment-modal-submit-btn{
    background: #405bcb;
    color: #fff;
    border: none;
    padding: 10px 10px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: all .25s;
    margin-top: 5px;
    width: 48.5%;
}

.add-payment-modal-submit-btn:hover{
    background: #364dad;
}

.add-payment-modal-cancel-btn{
    background: #9aaab9;
    color: #fff;
    border: none;
    padding: 10px 10px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: all .25s;
    margin-top: 5px;
    width: 48.5%;
}

.add-payment-modal-cancel-btn:hover{
    background: #73808b;
}