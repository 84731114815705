/*====== SIDEBAR ======*/
header.top-header, .sidebar{
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 220px;
  padding: 10px 14px;
  background: var(--sidebar-color);
  transition: var(--tran-01);
  z-index: 1000;
}

.sidebar-contain{
    position: relative;
    z-index: 910;
    background: #fff;
}

.sidebar.close{
    width: 88px;
}

.sidebar .text {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color);
    /* transition: var(--tran-03); */
    white-space: nowrap;
    opacity: 1;
}
  
.sidebar.close .text{
    opacity: 0;
    display: none;
}

.sidebar .image {
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar .image .small-logo, .sidebar .image .big-logo{
    transition: var(--tran-04);
}

.sidebar .image .small-logo{
    margin: 3px;
    margin-top: 8px;
    margin-bottom: 5px;
}

// SMILES LOGO
// .sidebar .image .big-logo{
//     width: 135px;
//     position: relative;
//     left: 35px;
// }

.sidebar .image .big-logo{
    // width: 208px;
    width: 177px;
    position: relative;
    left: 0px;
    top: -3px;
    margin: 6px 0px;
}

.hide-logo{
    display: none;
}

.sidebar li {
    height: 50px;
    margin-top: 10px;
    list-style: none;
    display: flex;
    align-items: center;
}

.sidebar li .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    min-width: 60px;
}

.sidebar li .icon{
    color: var(--text-color);
    transition: var(--tran-02);
}

.sidebar li .text{
    color: var(--text-color);
}

.sidebar li a{
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 6px;
}

.sidebar li a:hover{
    background: var(--primary-color);
    transition: var(--tran-04);
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text{
    color: var(--sidebar-color);
}

.sidebar li.sidebar-active{
    background: var(--primary-color);
    transition: var(--tran-04);
    border-radius: 6px;
}

.sidebar li.sidebar-active a{
    color: #fff!important;
}

.sidebar header {
  display: flex;
  position: relative;
  margin-bottom: 6px;
  /* margin-bottom: 14px; */
  
}

.sidebar .image-text img {
//   width: 39px;
  width: 50px;
  border-radius: 6px;
  padding: 7px;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;  
}

.sidebar header .image-text .header-text {
  display: flex;
  flex-direction: column;
}

.sidebar .header-text .name {
  font-weight: 600;
}

.sidebar .header-text .welcome-title {
  margin-top: -2px;
}

.toggle{
    position: fixed;
    top: 30px;
    left: 207px;
    /* transform: translateY(-50%); */
    width: 25px;
    height: 25px;
    background: var(--contrast-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--sidebar-color);
    font-size: 22px;
    line-height: 33px;
    // transition: var(--tran-02);
    cursor: pointer;
    z-index: 1001;
}

.toggle:hover{
    background: var(--text-color);
}

.bx-chevron-right{
    left: 75px;
}

.sidebar .menu-bar{
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid #f7f7f7;
}

.sidebar .menu-bar .mode{
    background: var(--primary-light-color);
    position: relative;
    border-radius: 6px;
}

.sidebar .menu-bar .mode .moon-sun{
    height: 50px;
    width: 60px;
    display: flex;
    align-items: center;
}

.sidebar .menu-bar .mode i{
    position: absolute;
}

.sidebar .menu-bar .mode i.sun{
    opacity: 0;
}

.sidebar .menu-bar .mode .toggle-switch{
    position: absolute;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 60px;
    cursor: pointer;
    background: var(--primary-light-color);
    border-radius: 6px;
}

.sidebar .toggle-switch .switch{
    position: relative;
    height: 22px;
    width: 44px;
    border-radius: 25px;
    background: #c6d6ea;
}

/****** Sidebar Slide Out ******/
.sidebar-sub{
    width: 250px;
    height: calc(100vh - 80px);
    background: red;
    background: #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    position: absolute;
    top: 80px;
    left: -250px;
    transition: var(--tran-02);
    padding: 20px;
    font-weight: normal;
    // z-index: 850;
    z-index: 10;
}

.sidebar-sub-active{
    // left: 88px;
    left: 220px;
    position: fixed;
}

.sidebar-sub-shift{
    left: 88px;
}

.link-active{
    font-weight: bold;
    background: var(--primary-light-color);
    color: var(--text-color);
    border-radius: 5px;
}

.sidebar-sub h4.title{
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color);
    margin-bottom: 10px;

}

.close-sidebar-sub{
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--text-color);
    font-size: 24px;
    cursor: pointer;
    transition: var(--tran-02);
}

.internal-sidebar-links{
    list-style: none;
}

.internal-sidebar-links li{
    margin: 5px 0px;
}

.internal-sidebar-links li a{
    text-decoration: none;
    // color: var(--primary-color);
    color: #8492a6!important;
    cursor: pointer;
    font-size: 13px;
}

.internal-sidebar-links li a.active{
    font-weight: bold;
    color: var(--primary-color);
}

.switch::before{
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    background: var(--sidebar-color);
    transition: var(--tran-03);
}

body.dark .switch::before{
    left: 24px;
}

body.dark .sidebar .toggle-switch .switch{
    background: #fff;
}

body.dark {
    color: var(--text-color)
}

body.dark .sidebar .menu-bar{
    border-top: #303030;
}
