.remit-expanded {
    width: 100%;
    padding: 30px;
    background: var(--body-color);
    position: relative;
}

.full-width-table {
    width: 100%;
    border-collapse: collapse; /* Optional: to ensure the table looks less cluttered */
}

.full-width-table th, .full-width-table td {
    padding: 10px; /* Optional: adds space around table cells */
    border: 1px solid #ddd; /* Optional: adds border to each cell */
}

.meta-add-btn{
    background: #405bcb;
    color: #fff;
    border: none;
    width: 45px;
    height: 45px;
    padding: 10px 10px;
    border-radius: 50px;
    font-size: 17px;
    cursor: pointer;
    transition: all .25s;
    margin-top: -2px
}

.meta-add-btn:hover{
    background: #364dad;
}

.remit-header{
    height: 65px;
    display: flex;
}

.remit-header-right{
    text-align: right;
    position: relative;
    justify-content: end;
    display: flex;
}

.remit-running-total{
    font-size: 28px;
    margin-right: 15px;
    margin-top: 0px;
}

.add-claim-note-btn{
    background: #9aaab9;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: all .25s;
    margin-top: -10px;
    margin-bottom: 20px;
}

.add-claim-note-btn:hover{
    background: #73808b;
}

.error-message {
    background: red;
    color:#fff;
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
  }

  .submit-button{
    background: #405bcb;
    color: #fff;
    border: none;
    padding: 10px 10px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: all .25s;
    margin-top: 5px;
    width: 20%;
    position: absolute;
    right: 29px;
    bottom: 15px;
}

.submit-button:hover{
    background: #364dad;
}