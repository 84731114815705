* {
  font-family: "Poppins", sans-serif!important;
  // font-family: "Montserrat", sans-serif;
  // font-optical-sizing: auto;
  
  // font-style: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


:root {
  /*====== Colors ======*/
  --body-color: #f7faff;
  --sidebar-color: #fff;
  --primary-color: #336cb8;
  --primary-light-color: #edf1f6;
  --toggle-color: #ddd;
  --text-color: #476d76;
  // --contrast-color: #ebb41f;
  --contrast-color: #476d76;
  --h1-color: #476d76;

  /*====== Transitions ======*/
  --tran-01: all 0.1s ease;
  --tran-02: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.4s ease;
  --tran-05: all 0.5s ease;

  /*====== Shadows ======*/
  --shadow-main: 0px 2px 6px rgb(0 0 0 / 6%);

  /*====== Borders ======*/
  --border-color: #f7f7f7;
}

body {
  height: 100vh;  
  background: var(--body-color);
  // transition: var(--tran-04);
}

h1{
  font-size: 25px;
  font-weight: 400;
  margin-bottom: -10px;
  letter-spacing: -1px;
  color: var(--h1-color);
}

*::selection {
  background: var(--primary-color);
  color: #fff;
}


/******************************/
/********** App Grid **********/
/******************************/
.loading{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: #fff;
  position: relative;
  z-index: 9999;
}

.loader-gif{
  max-width: 80px;
}

// this is the background from any card, panel, table, etc.
.panel{
  background: #fff;
  padding: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

/******************************/
/********** App Grid **********/
/******************************/
.flex-grid {
  display: flex;
  margin: 0 0 20px 0;
  gap: 20px;
}
.flex-grid .col {
  flex: 1;  
}

.col {
  // background: salmon;
  padding: 20px;
}

.flex-grid-half {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.flex-grid-half .col {
  width: 49%;
}

.flex-grid-thirds {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.flex-grid-thirds .col {
  width: 32%;
}

.flex-grid-quarters {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.flex-grid-quarters .col {
  width: 24%;
}