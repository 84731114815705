.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    position: absolute;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    background: unset!important;
    color:#000!important;
  }

  .close-modal:hover{
    background: unset!important;
  }

  .close-modal-btn{
    border-radius: 5px;
    margin-top: 10px;
    background: #73808b;
    color:#fff;
  }