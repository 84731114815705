table {
    border-collapse: separate;
    border-spacing: 0 0px;
    width: 100%;
  }
  
th{
  text-align: left;
  padding-left: 10px;
  padding-bottom: 25px;
}
  
  .claim-row td {
    padding: 10px;
    // background-color: #f9f9f9;
    // border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  
  .claim-row td:first-child {
    // border-left: 1px solid #ccc;
    // border-top-left-radius: 5px;
    // border-bottom-left-radius: 5px;
  }
  
  .claim-row td:last-child {
    // border-right: 1px solid #ccc;
    // border-top-right-radius: 5px;
    // border-bottom-right-radius: 5px;
  }

  .add-claim-button{
    background: #405bcb;
    color: #fff;
    border: none;
    padding: 10px 10px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.25s;
    margin-top: 5px;
    width: 20%;
    margin-bottom: 20px;
    float: right;
  }

  .claims-submit-button{
    background: #405bcb;
    color: #fff;
    border: none;
    padding: 10px 10px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: all .25s;
    width: 100%;
    height: 100%;
}

.filter-container{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.filter-item{
  display: flex;
  width: 100%;
}