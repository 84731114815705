.note-list{

}

.note-list li {
    list-style-type: none;
    margin-bottom: 10px;
}

.add-note-btn{
    background: #405bcb;
    color: #fff;
    border: none;
    padding: 10px 10px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: all .25s;
    margin-top: 10px;
    width: 100%;
}

.add-note-btn:hover{
    background: #364dad;
}

.notes-container{
    padding: 15px;
    background: var(--primary-light-color);
    border-radius: 5px;
    margin-top: 10px;
}