.header-context-menu{
    background: #fff;
    display: flex;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 20%);
    position: absolute;
    border-radius: 6px;
    padding: 0px 10px;
    // top: 73px;
    // right: 40px;
    transform: translate3d(-20px, 155px, 0px);
    visibility: hidden;
    

    &.active{
        visibility: visible;        
    }

    ul{
        list-style-type: none;
        margin:0;

        .split-line{
            width: 100%;
            height: 1px;
            background: #e2e8f099;
        }

        li{
            padding: 5px 20px;
            font-size: 16px;
            margin: 10px 0px;
            display: flex;
            align-items: center;
            border-radius: 3px;
            a{
                color: var(--text-color);
                text-decoration: none;
                display: flex;
                align-items: center;

                i.icon{
                        margin-right: 10px;
                        font-size: 17px;
                        font-weight: 400;
                        display: flex;
                        align-items: center;
                }
            }
        }

        li:hover{
            background: #edf1f6;
        }
    }
}

body.active-context-menu{
    .header-context-menu{
        visibility: hidden;
    }
}