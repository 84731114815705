.tabs {
  display: flex;
  position: relative;
  top: -20px;
  margin-left: -20px;
  justify-content: space-between;
  margin-right: -20px;
  }
  
  button {
    // width: 100%; effecting other buttons since its targeted at the word button.  adjust this
    padding: 10px 20px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  button:focus, button:hover {
    background-color: #ddd;
  }
  
  .tab-content {
    margin-top: 20px;
  }