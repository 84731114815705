.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 80%;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-actions {
    margin-top: 20px;
    display: flex;
    width: 100%;
  }
  
  .modal-actions button {
    margin-left: 10px;
  }
  
  .confirm-modal-submit-btn{
    background: #405bcb;
    color: #fff;
    border: none;
    padding: 10px 10px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: all .25s;
    margin-top: 5px;
    width: 100%;
}

.confirm-modal-submit-btn:hover{
    background: #364dad;
}

.confirm-modal-cancel-btn{
    background: #9aaab9;
    color: #fff;
    border: none;
    padding: 10px 10px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: all .25s;
    margin-top: 5px;
    width: 100%;
}

.confirm-modal-cancel-btn:hover{
    background: #73808b;
}