.patient-data-contain{
    min-height: 400px;
}

.claim-notes-contain{
    min-height: 400px;
    max-height: 400px;
}

.claim-notes{
    max-height: 295px;
    overflow: scroll;
}

td{
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 15px;
}