body{
    position: relative;
}

.hr-spacer{
    display: block;
    width: 100%;
    height: 1px;
    background: #bcc8db;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    opacity: .5;
}

::selection {
    background: #0090c9;
    color: #fff;
  }

button {
    background: #0090c9!important;
    border-radius: 5px;
    color:#fff;
    transition: all .25s;
}

button:hover{
    background: #476d76!important;
}

.login-screen{
    background-image: linear-gradient(to right top, #446bb3, #008eca, #00acb7, #00c287, #9ece54);
    width: 100%;
    height: 100vh;
    
    .login-contain{
        background:  #fff;
        width: 400px;
        height: 400px;
        border-radius: 10px;
        box-shadow: var(--shadow-main);
        padding: 40px;
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
        // top: 250px;
        top: 30%;
        
        .login-logo{
            width: 250px;
            margin: 0 auto;
            display: block;
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .login-desc{
            text-align: center;
            line-height: 20px;
            margin-bottom: 40px;
            color:#4f6c75;
        }

        .login-screen-btn{
            background: #4f6c75;
            color: #fff;
            width: 100%;
            height: 50px;
            border-radius: 5px;
            padding: 15px;
            cursor: pointer!important;
            transition: all .25s;
        }

        .login-screen-btn:hover{
            background: #394f55;
        }

        .login-footer{
            font-size: 12px;
            text-align: center;
            margin-top: 10px;
            color: #4397bf;
        }

    }
}

.main-content{
    margin-left: 220px;
    // padding: 40px;
    padding: 20px;
    transition: var(--tran-02);
    background: var(--body-color);
}

.main-content-expanded {
    margin-left: 88px;
}

.main-content-shift{
    margin-left: 338px;
}

input[type="text"],input[type="date"], input[type="textarea"], select, textarea{
    width: 100%!important;
    padding: 10px 15px!important;
    border-radius: 3px!important;
    border: none!important;
    border: 1px solid #bcc8db!important;
    font-size: 14px!important;
}

.form-btn{
    background: #3e4ecf;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: all 0.25s;
}


h1{
    font-size: 24px;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 20px;
}

h2{
    font-size: 20px;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 20px;
}

h3{
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color);
}

.container{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.contain-10{
    width: 10%;
}

.contain-20{
    width: 20%;
}

.contain-30{
    width: 30%;
}

.contain-33{
    width: 33%;
}

.contain-40{
    width: 40%;
}

.contain-50{
    width: 50%;
}

.contain-60{
    width: 60%;
}

.contain-70{
    width: 70%;
}

.contain-80{
    width: 80%;
}

.contain-90{
    width: 90%;
}

.contain-100{
    width: 100%;
}



td, th{
    
}

td{
    font-weight: 400;
    font-size: 13px;
}

th{
    font-weight: 500;
    font-size: 14px;
}

.no-shadow{
    box-shadow: none!important;
}

// ****** TAGS ****** //
.tag{
    text-align: center;
    font-size: 14px;
    padding: 3px 15px;
    border-radius: 20px;
}

.tag-primary{
    background: #414ec7;
    color: #fff;
}

.tag-denied{
    background: #d53739;
    color: #fff;
}

.search-bar {
    margin-bottom: 20px;
  }
  
  .search-bar input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-claim-btn{
    background: #0090c9;
    color:#fff;
    border-radius: 5px;
    transition: all .25s;
  }

  .add-claim-btn:hover{
    background: #00b99a;
  }