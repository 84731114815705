.notify {
    background: #2ebd6e;
    width: 25%;
    min-width: 300px;
    padding: 10px;
    text-align: center;
    color: white;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
    z-index: 2000;
    margin: 0 auto;
    transition: top 0.25s ease-in-out, opacity 0.25s ease-in-out;
    opacity: 0;
}

.notify-show {
    top: 15px;
    opacity: 1;
}

.hide-notify{
    position: absolute;
    top: 8px;
    right: 20px;
    font-size: 20px;
    cursor: pointer!important;
    display: block;
    color:#fff;
    text-decoration: none;
}