header.top-header{
    margin-left: 220px;
    padding: 20px 20px 20px 30px;
    transition: var(--tran-01);
    background: var(--sidebar-color);
    border-left: 1px solid var(--body-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
}

header.top-header-expanded {
    margin-left: 88px;
}

header.top-header h3{
    font-size: 18px;
    font-weight: 400;
}

.header-start{
    display: flex;
    flex: 1
}

.header-title{
    display: flex;
    align-items: left;
    justify-content: start;
    margin-right: 20px;
    flex-direction: column;
    // color: var(--primary-color)
    color: #556a73;
}

.header-title small{
    margin-top: -6px;
    color: #bbb;
    font-size: 12px;
}


.header-end{
    display: flex;
    align-items: center;
    justify-content: end;
    flex: 1;
}

.header-end .create-patient{
    display: flex;
    height: 100%;    
    background: var(--primary-color);
    color: white;
    outline: none;
    border: none;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 10px 20px;
    cursor: pointer;
    transition: var(--tran-02)
}

.header-end .create-patient:hover{
    background: var(--contrast-color);
}

.header-end .search-box{
    display: flex;
    background-color: var(--primary-light-color);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    padding: 10px 40px 10px 10px;
}

.header-end .search-box input{
    height: 100%;
    width: 100%;
    background-color: var(--primary-light-color);
    outline: none;
    border: none;
    border-radius: 6px;
    margin-left: 10px;
    width: 150px;
}



.fullscreen {
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}



.notification .notify-badge{
    width: 8px;
    height: 8px;
    background: red;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    top: -1px;
    right: -1px;
}



.header-end .profile-contain{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-left: 10px;
    cursor: pointer;
    transition: var(--tran-02);
}

.header-end .profile-contain:hover{
    color: var(--primary-color);
}

.header-end .profile-text{
    display: flex;
    align-items: center;
}

.header-end .profile-text .profile-caret{
    font-size: 22px;
}

.header-end .profile-btn{
    width: 35px;
    height: 35px;
    background: #ccc;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 5px;
}

.header-icon{
    font-size: 22px;
    margin-left:10px;
    color: var(--text-color);
    transition: var(--primary-color);
    cursor: pointer;
}

.header-icon:hover{
    color: var(--primary-color)
}

.utility-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 10px 30px;
    background: #fff;
    color: var(--text-color);
    box-shadow: var(--shadow-main);
    font-size: 14px;
    font-weight: 500;
    z-index: 0;
    margin-top: -20px;
    margin-bottom: 20px;
    margin-left: -20px;
    margin-right: -20px;
    position: sticky;
    top: 80px;
    height: 55px;
    margin-left: 250px;
}
